export const environment = {
    production: true,
    apiUrl: "https://api.kobize.dev.ekowitryna.pl/api",
    keycloakUrl: "https://keycloak-dev.mobiletract.com",
    realm: "bdo-dev",
    clientId: "ekowitryna_kobize",
    googleTagManagerId: 'GTM-KTDGNR8B',
    googleTagManagerPreview: 'env-3',
    googleTagManagerAuth: 'UoW9eU_mR1fXqZpYYgvkkg'

};
